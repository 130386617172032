
export const balanceCols = [
  {
    prop: 'month',
    label: '月份',
    minWidth: '200',
    sortable: true
  },
  {
    prop: 'startTime',
    label: '起始时间',
    minWidth: '200'
  },
  {
    prop: 'endTime',
    label: '结束时间',
    minWidth: '200'
  },
  {
    prop: 'amount',
    label: '需还款金额',
    minWidth: '200'
  },
  {
    prop: 'isRepayment',
    label: '是否还款',
    minWidth: '200',
    slotName: 'isRepayment'
  },
  {
    prop: 'repaymentTime',
    label: '还款日期',
    minWidth: '200',
    slotName: 'repaymentTime',
    sortable: true
  },
  {
    prop: 'repaymentCompletionTime',
    label: '还款完成时间',
    minWidth: '200'
  }
]
