<template>
  <div>
    <search-header :query="query" @search="searchChange" />
    <CommonTable
      :selection="false"
      :tableLoading="tableLoading"
      :cols="cols"
      :infoData="data"
      @sort-change="sortChange"
    >
      <template #isRepayment="{ scoped: row }"> {{ row.isRepayment === 0 ?'未还款':'已还款' }} </template>
      <template #repaymentTime="{ scoped: row }"> {{ parseTime(row.repaymentTime) }} </template>
      <el-table-column fixed="right" label="操作" width="300px" align="center">
        <template slot-scope="{ row }">
          <color-text-btn @click="handleDetail(row)">详情</color-text-btn>
        </template>
      </el-table-column>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import searchHeader from './module/searchHeader.vue'
import initDataMixin from '@/mixins/initData'
import { parseTime } from '@/utils'
import { balanceCols as cols } from './module/cols'

export default {
  components: { searchHeader },
  mixins: [initDataMixin],
  data() {
    return {
      cols,
      sup_this: this,
      url: '/externaladmin/financeService/creditMonthBill/basicList',
      query: {
        userId:this.$route.query.id
      }
    }
  },
  methods: {
    // 结账日期设置
    dateSet() {
      this.visible = true
    },
    parseTime,
    sortChange({ prop, order }) {
      if (prop == 'levelName') {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'member_level',
              asc: state
            }
          ]
        }
        this.init()
      } else if (prop == 'accountAmount') {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'account_amount',
              asc: state
            }
          ]
        }
        this.init()
      } else if ((prop = 'updateTime')) {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'update_time',
              asc: state
            }
          ]
        }
        this.init()
      }
    },
    handleDetail(data) {
      const { id } = data
      this.$router.push({ path: '/financialManagement/userMonthBillsList', query: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
</style>
