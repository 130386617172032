<template>
  <div class="app-container userCreditBill">
    <div class="listHeader mb30">
      <listStateGroup v-model="status" :list="tabs"></listStateGroup>
      <div>
        <el-button uiid="zd-back" size="small" type="primary" @click="back">返回</el-button>
      </div>
    </div>
    <template v-for="item in tabs">
      <component :id="id"  v-if="status === item.value" :key="item.value" :is="item.value"></component>
    </template>
  </div>
</template>

<script>
import { tabs } from './module/const'
import listStateGroup from '@/views/components/listStateGroup'
import unsettledBills from './unsettledBills'
import userMonthBills from './userMonthBills'
export default {
  components: {
    listStateGroup,
    unsettledBills,
    userMonthBills
  },
  props: {},
  data() {
    return {
      tabs,
      status: 'userMonthBills',
      id:Number(this.$route.query.id)
    }
  },
  computed: {},
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.userCreditBill {
  font-size: 25px;
  .listHeader {
    display: flex;
    .list-state-group {
      width: 90%;
    }
  }
}
</style>
