<template>
  <div class="search-warpper formPart">
    <el-form :inline="true" :model="query" class="demo-form-inline" uiid="zd-search">
      <el-form-item label="订单号：">
        <el-input
          @change="$emit('search')"
          uiid="zd-orderCode"
          size="mini"
          style="width: 240px"
          clearable
          placeholder="请输入订单号"
          v-model="query.orderCode"
        ></el-input>
      </el-form-item>
      <el-form-item label="使用时间：">
        <el-date-picker
          v-model="timeSelect.time"
          type="daterange"
          size="small"
          value-format="yyyy-MM-dd"
          placeholder="请选择时间"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 300px"
          @change="$emit('search')"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          uiid="zd-submit"
          style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
          type="primary"
          @click="$emit('search')"
          icon="el-icon-search"
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    timeSelect:{
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .el-form-item {
    margin-right: 20px;
  }
}
</style>
