export const tabs = [
  {
    label: '月结账单',
    value: 'userMonthBills'
  },
  {
    label: '未出账单',
    value: 'unsettledBills'
  }
]
