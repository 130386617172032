<template>
  <div class="search-warpper formPart">
    <el-form :inline="true" :model="query" class="demo-form-inline">
      <el-form-item label="月份选择：">
         <el-date-picker
          size="mini"
          @change="$emit('search')"
          style="width: 200px"
          v-model="query.month"
          value-format="yyyy-MM"
          type="month"
          placeholder="选择月"
          clearable
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="还款状态：">
        <el-select
          size="mini"
          @change="$emit('search')"
          style="width: 200px"
          v-model="query.isRepayment"
          clearable
          placeholder="请选择还款状态"
        >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
          type="primary"
          @click="$emit('search')"
          icon="el-icon-search"
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [
        {
          label: '已还款',
          value: 1
        },
        {
          label: '未还款',
          value: 0
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .el-form-item {
    margin-right: 20px;
  }
}
</style>
